import React from 'react'
import "../assets/css/bootstrap.min.css";
import "../assets/css/main.css";
import ColorNavbar from '../components/colornavbar';
import Helmet from 'react-helmet';
import smoothscroll from 'smoothscroll-polyfill';
import ogImage from "../images/projekte-felix-mottl-strasse-16-1.jpg";

const Layout = ({ props, children, location, title, description, keywords, robots, theme }) => {
  if (typeof window !== `undefined`) {
    smoothscroll.polyfill();
  }
  React.useEffect(() => {
    const updateView = () => {
      let descriptionItems = document.querySelectorAll("h2.description, h3.title, .card.plain, .card.about, .card.icon, .card.large");
      const windowHeight = window.innerHeight;
      for (let i = 0; i < descriptionItems.length; i++) {
        if ((descriptionItems[i].classList.contains("card") && windowHeight - (descriptionItems[i].getBoundingClientRect().top - (windowHeight * 0.15)) - (descriptionItems[i].getBoundingClientRect().height * 0.2) > 0) || (!descriptionItems[i].classList.contains("card") && windowHeight - descriptionItems[i].getBoundingClientRect().top - (descriptionItems[i].getBoundingClientRect().height * 0.2) > 0)) {
          if (!descriptionItems[i].classList.contains("visible")) {
            descriptionItems[i].classList.add("visible");
          }
        }
      }
    };
    if (typeof window !== `undefined` && ['/'].includes(window.location.pathname)) {
      window.addEventListener("scroll", updateView);
    }
    return () => {
      if (typeof window !== `undefined`) {
        if (['/'].includes(window.location.pathname)) {
          window.removeEventListener("scroll", updateView);
        }
      }
    };
  });
  function getHelmet() {
    return (
      <Helmet
        title={title}
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no' },
        ]}
      >
        <script type="text/plain" className="cmplazyload"
                data-cmp-src="https://maps.googleapis.com/maps/api/js?v=3&sensor=false&callback=initialize&key=AIzaSyAk0c_JEsavAkWRh3QUWECOnZ1rKWsZKZY"
                data-cmp-vendor="s1104"></script>
        <script type="text/plain" async className="cmplazyload" src="https://www.googletagmanager.com/gtag/js?id=G-WT2C0S19RF" data-cmp-vendor="s905"></script>
        <script type="text/plain" className="cmplazyload" data-cmp-vendor="s905">
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', 'G-WT2C0S19RF', { 'anonymize_ip': true });
            `}
        </script>

        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta httpEquiv="Content-Language" content="de-DE" />
        <meta name="description" content={description} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:type" content="website" />
        <meta name="og:image" content={`https://Antje Abel Architekten/${ogImage}`} />
        <meta name="keywords" content={keywords + 'Antje Abel Architekten'} />
        <meta name="robots" content={robots !== '' ? robots : 'noindex, follow'} />
        <meta name="author" content="Antje Abel" />
        <link rel="canonical" href={location.href} />
      </Helmet>
    )
  }

  return (
      <>
        {getHelmet()}
        <ColorNavbar theme={theme} />
        <div>
          {children}
        </div>
      </>
  );
};

export default Layout
